<template >
   <footer class="footer_section">
      <div class="bg-light-gray py-5 top_section_footer">
         <div class="container">
            <div class="row">
               <div class="col-md-6">
                  <img src="../../assets/images/logo_white.svg" alt="Abellio" class="logo_color"/>
               </div>
               <div class="col-md-3">
                  <ul class="list-unstyled p-0 m-0">
                     <li class="heading">{{ __t('footer.quick_start') }}</li>
                     <li v-for="(menuItem, index) in menu.footerMenu.menu_items" :key="index">
                        <router-link v-if="localeMenuItem(menuItem)" :to="localePrefix + localeMenuItem(menuItem).link"
                                     :target="menuItem.is_new_tab ? '_blank' : '_self'">
                           {{ localeMenuItem(menuItem).title }}
                        </router-link>
                     </li>
                  </ul>
               </div>
               <div class="col-md-3">
                  <div class="heading">{{ footer.footer_title }}</div>
                  <div v-html="footer.footer_description"></div>
                  <div class="contact-data">
                     <div class="data-phone">
                        <i class="icon-phone"></i>
                        <strong>{{ __t('footer.hot_line') }}:</strong>
                        <span>{{ __t('footer.hot_line_nr') }}</span>
                     </div>

                     <div class="data-mail">
                        <router-link v-if="footer.footer_link" :to="footer.footer_link">
                           <i class="icon-mail"></i><span><strong>{{ __t('footer.to_contact_form') }}</strong></span>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="container copyright-container border-top py-3 bottom_section_footer">
         <div class="row align-items-center d-flex">
            <div class="col-md-6">
               <!-- <p class="copyright text-muted p-0">© 2020 Abellio.</p> -->
            </div>
            <div class="col-md-6 text-md-right">
               <ul class="list-unstyled footer-ul m-0 p-0">
                  <li v-for="(menuItem, index) in menu.bottomFooterMenu.menu_items" :key="index">
                     <router-link class="text-muted btn-link" v-if="localeMenuItem(menuItem)"
                                  :to="localePrefix + localeMenuItem(menuItem).link"
                                  :target="menuItem.is_new_tab ? '_blank' : '_self'">
                        {{ localeMenuItem(menuItem).title }}
                     </router-link>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
   import {mapState} from "vuex";

   export default {
      name: "Footer",
      computed: {
         ...mapState([
            "menu",
            "region",
            "locales",
            "settings"
         ]),
         localePrefix() {
            return this.locales.currentLocale != this.locales.defaultLocale
               ? "/" + this.locales.currentLocale
               : "";
         },
         footer() {
            if (this.settings.loaded) {
               return this.settings.all.footer_info[this.locales.currentLocale];
            }

            return {
               footer_title: "",
               footer_link: "",
               footer_description: ""
            }
         }
      },
      methods: {
         localeMenuItem(menuItem) {
            if (menuItem.locales_permalink[this.locales.currentLocale]) {
               return menuItem.locales_permalink[this.locales.currentLocale];
            }
            return null;
         }
      },
      created() {
         this.$store.dispatch("menu/getFooterMenu");
         this.$store.dispatch("menu/getBottomFooterMenu");
      },
      watch: {
         "region.currentRegion": function () {
            this.$store.dispatch("menu/getFooterMenu");
            this.$store.dispatch("menu/getBottomFooterMenu");
         },
         "locales.currentLocale": function () {
            this.$store.dispatch("menu/getFooterMenu");
            this.$store.dispatch("menu/getBottomFooterMenu");
         },
      }
   }
</script>
